import Swiper, { Autoplay, EffectCards, EffectCreative, EffectCoverflow, EffectFade, FreeMode, Mousewheel, Navigation, Lazy, Pagination, Thumbs } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/lazy/lazy.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import 'swiper/modules/effect-cards/effect-cards.scss';
import 'swiper/modules/effect-coverflow/effect-coverflow.scss';
import 'swiper/modules/effect-fade/effect-fade.scss';
import 'swiper/modules/effect-creative/effect-creative.scss';
import 'swiper/modules/mousewheel/mousewheel.scss';
import 'swiper/modules/thumbs/thumbs.scss';
import { SwipeEventListener } from 'swipe-event-listener'

document.addEventListener('DOMContentLoaded', function() {

    const areas = document.getElementsByClassName('js-areasSwiper')[0],
          benefits = document.getElementsByClassName('js-benefitsSwiper')[0],
          benefits2 = document.getElementsByClassName('js-benefits2Swiper')[0],
          departments = document.querySelector('#departments'),
          devprogram = document.getElementsByClassName('js-devprogramSwiper')[0],
          numbers = document.getElementsByClassName('js-numbersSwiper')[0],
          header = document.getElementsByClassName('js-headerSwiper')[0],
          heropro = document.querySelector('.js-heroProSwiper'),
          hybrid = document.getElementsByClassName('js-hybridSwiper'),
          practicesInternships = document.getElementsByClassName('js-practicesInternshipsSwiper')[0],
          life = document.getElementsByClassName('js-lifeSwiper')[0];

    const debounce = function(func, time){
        var time = time || 100;
        var timer;
        return function(event){
            if(timer) clearTimeout(timer);
            timer = setTimeout(func, time, event);
        };
    }

    window.carousels = function() {
        const thisIndex = function(elm) {
            let nodes = elm.parentNode.childNodes, node, i = 0, count = 0;
            while( (node = nodes.item(i++)) && node != elm ) {
                if( node.nodeType == 1 ) count++;
            }

            return count;
        };

        const areasCarousel = function() {
            const carousel = areas;
            const team = document.getElementsByClassName('js-teamCarousel')[0];
            let status = false, swiper, touchStatus = false;

            const swipeAction = function(obj) {
                let touchstartX = 0;
                let touchendX = 0;
                let i = swiper.realIndex + 1;

                const { swipeArea, updateOptions } = SwipeEventListener({
                    swipeArea: document.querySelector('.js-teamCarousel'),
                });

                swipeArea.addEventListener('swipeLeft', () => {
                    i++;
                    i > 3 ? i = 1 : false;
                    swiper.slideNext();
                    //swiper.slideTo(i);
                });

                swipeArea.addEventListener('swipeRight', () => {
                    i--;
                    i < 0 ? i = 2 : false;
                    swiper.slidePrev();
                    //swiper.slideTo(i);
                });

                touchStatus = true;
            };

            const carousels = {
                destroy: function(el) {
                    status === true ? swiper.destroy() : false;
                    status = false;
                },

                start: function(el) {
                    swiper = new Swiper(carousel, {
                        createElements: true,
                        modules: [Pagination],
                        loop: true,
                        pagination: {
                            el: carousel.parentNode.getElementsByClassName('swiper-pagination')[0],
                            clickable: true,
                        },
                        slidesPerView: 1,
                        speed: 500
            	    });

            	    swiper.on('slideChange', function() {
                	    team.classList.remove('step-0', 'step-1', 'step-2');
                	    team.classList.add('step-'+swiper.realIndex);
            	    });

            	    status = true;

            	    if (touchStatus === false) {
                        swipeAction();
                    }
                },

                toggle: function() {
                    if (window.innerWidth <= 1024) {
                        if (status === false) {
                            carousels.start(carousel);
                        }

                    } else {
                        carousels.destroy(carousel);
                    }
                }
            }

            carousels.toggle();
            window.addEventListener('resize', carousels.toggle);
        };

        const benefitsCarousel = function() {
            const swiper = new Swiper(benefits, {
                centeredSlides: false,
                createElements: true,
                modules: [Navigation, Pagination],
                loop: false,
                navigation: {
                    nextEl: benefits.parentNode.getElementsByClassName('swiper-button-next')[0],
                    prevEl: benefits.parentNode.getElementsByClassName('swiper-button-prev')[0]
                },
                pagination: {
                    el: benefits.parentNode.getElementsByClassName('swiper-pagination')[0],
                    clickable: true
                },
                slidesPerGroup: 1,
                slidesPerView: 'auto',
                spaceBetween: 20,
                speed: 500,
                breakpoints: {
                    520: {
                        centeredSlides: false,
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 0,
                        slidesPerGroup: 2,
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    768: {
                        enteredSlides: false,
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 0,
                        slidesPerGroup: 2,
                        slidesPerView: 5,
                        spaceBetween: 20,
                    },
                    1024: {
                        centeredSlides: false,
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 0,
                        slidesPerGroup: 2,
                        slidesPerView: 6,
                        spaceBetween: 24,
                    }
                }
            });

            window.addEventListener("resize", debounce( () => swiper.update(), 150 ));
        };

        const benefits2Carousel = function() {
            let desktop = false, mobile = false, swiper, swiperArticle;

            const swiperDesktop = function() {
                if (mobile === true) {
                    swiperArticle.destroy(1,1);
                    mobile = false;
                }

                swiperArticle = new Swiper(".js-benefits2ArticlesSwiper", {
                    allowTouchMove: false,
                    createElements: true,
                    loop: true,
                    slidesPerView: 1,
                    watchSlidesProgress: true,
                });

                swiper = new Swiper(benefits2, {
                    centeredSlides: true,
                    coverflowEffect: {
                        rotate: 0,
                        stretch: 92,
                        depth: 80,
                        modifier: 1.2,
                        scale: .87,
                        slideShadows: false,
                    },
                    loop: true,
                    createElements: true,
                    effect: "coverflow",
                    modules: [EffectCoverflow, Navigation, Thumbs],
                    navigation: {
                        nextEl: document.querySelector('#benefits2').querySelector('.swiper-button-next'),
                        prevEl: document.querySelector('#benefits2').querySelector('.swiper-button-prev')
                    },
                    loop: true,
                    slideToClickedSlide: true,
                    slidesPerView: 'auto',
                    speed: 500,
                    spaceBetween:15,
                    thumbs: {
                        swiper: swiperArticle,
                    },
                });

                desktop = true;
            }

            const swiperMobile = function() {

                if (desktop === true) {
                    swiper.destroy(1,1);
                }

                swiperArticle = new Swiper(".js-benefits2ArticlesSwiper", {
                    createElements: true,
                    loop: true,
                    modules: [Pagination],
                    pagination: {
                        el: document.querySelector('#benefits2').querySelector('.swiper-pagination'),
                        clickable: true,
                    },
                    slidesPerView: 1,
                });

                desktop = false;

                if (mobile === false) {
                    mobile = true;
                }
            }

            const toggle = function() {
                if (window.innerWidth > 1024) {
                    desktop === false ? swiperDesktop() : false;
                } else {
                    mobile === false ? swiperMobile() : false;
                }
            }

            toggle();
            window.addEventListener("resize", debounce( () => toggle(), 150 ));

        };

        const departmentsCarousel = function() {
            let swiperBlocks, swiperArticles, status = false, triggerRes = 768;
//            window.swiperFaq;

            const departmentsBlocksSwiper = () => {
                const start = () => {
                    swiperBlocks = new Swiper(document.querySelector('.js-departmentsBlocksSwiper'), {
                        centeredSlides: true,
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 1,
                            depth: 80,
                            modifier: 1.2,
                            scale: .87,
                            slideShadows: false,
                        },
                        effect: "coverflow",
                        modules: [EffectCoverflow, Pagination],
                        loop: true,
                        pagination: {
                            el: departments.getElementsByClassName('swiper-pagination')[0],
                            clickable: true
                        },
                        slidesPerView: 'auto',
                        slideToClickedSlide: true
                    });

                    swiperBlocks.on('slideChange', () => {
                        swiperArticles.slideTo(swiperBlocks.realIndex);
                        window.swiperFaq.slideTo(swiperBlocks.realIndex);
                    });
                }

                const destroy = () => {
                    swiperBlocks.destroy(1,1);
                }

                const trigger = document.querySelector('.js-departmentsBlocksSwiper').querySelectorAll('.swiper-slide');

                for (let i = 0; i < trigger.length; i++) {
                    trigger[i].addEventListener('click', function(e) {

                        if (window.innerWidth > triggerRes) {
                            document.querySelector('.js-departmentsBlocksSwiper').
                                     querySelector('.is-active').classList.remove('is-active');
                            swiperArticles.slideTo( thisIndex(e.currentTarget));
                            window.swiperFaq.slideTo( thisIndex(e.currentTarget));

                            window.runScroll('.c-departments__articles', 0);

                            e.currentTarget.classList.add('is-active');
                        }

                        //document.documentElement.dataset.department = e.currentTarget.dataset.id;
                    });
                }

                const toggle = () => {
                    if (window.innerWidth <= triggerRes) {
                        if (status === false) {
                            start();
                            status = true;
                        }
                    } else {
                        if (status === true) {
                            destroy();
                            status = false;
                        }
                    }
                }

                toggle();
                window.addEventListener("resize", debounce( () => toggle(), 150 ));
            }

            const departmentsArticlesSwiper = () => {
                swiperArticles = new Swiper(document.querySelector('.js-departmentsArticlesSwiper'), {
                    autoHeight: true,
                    allowTouchMove: false,
                    createElements: true,
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    modules: [EffectFade],
                    slidesPerView: 1,
                    on: {
                        slideChange(s) {
                            document.documentElement.dataset.department = s.slides[s.activeIndex].dataset.id;
                        },
                    },
                });
            }

            const departmentsFaqSwiper = () => {
                window.swiperFaq = new Swiper(document.querySelector('.js-departmentsFaqSwiper'), {
                    allowTouchMove: false,
                    autoHeight: true,
                    createElements: true,
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    modules: [EffectFade],
                    slidesPerView: 1,
                });
            }

            departmentsArticlesSwiper();
            departmentsFaqSwiper();
            departmentsBlocksSwiper();
        }

        const devprogramCarousel = function() {
            const swiper = new Swiper(devprogram, {
                centeredSlides: false,
                modules: [Pagination],
                loop: false,
                pagination: {
                    el: devprogram.parentNode.getElementsByClassName('swiper-pagination')[0],
                    clickable: true
                },
                slidesPerView: 1,
                spaceBetween: 20,
                speed: 500,
                breakpoints: {
                    768: {
                        slidesPerView: 3,
                    }
                }
            });
        };

        const headerCarousel = () => {
            const swiper = new Swiper(document.querySelector('.js-headerSwiper'), {
                allowTouchMove: false,
                autoplay: {
                    delay: 4500
                },
                createElements: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                modules: [Autoplay, EffectFade],
                slidesPerView: 1,
            });
        }

        const heroProCarousel = () => {
            const swiper = new Swiper(heropro, {
                autoplay: {
                    delay: 2500
                },
                createElements: true,
                lazy: {
                    checkInView: true,
                    loadPrevNext: true,
                    loadPrevNextAmount: 1
                },
                effect: "creative",
                    creativeEffect: {
                    prev: {
                        origin: "left center",
                        translate: ['-10%', 0, 0],
                        opacity: 0
                    },
                    next: {
                        origin: "left center",
                        translate: [0, 0, 0],
                        rotate: [0, -10, 0],
                        opacity: 0
                    },
                },
                loop: true,
                modules: [Autoplay, EffectCreative, Lazy],
                slidesPerView: 1,
                speed: 1500,
            });
        }

        const hybridCarousel = function() {
            for (let i = 0; i < hybrid.length; i++) {
                const carousel = hybrid[i];
                    let status = false, swiper;
                    let slides = hybrid[i].getElementsByClassName('swiper-slide');

                const carousels = {
                    destroy: function(el) {
                        status === true ? swiper.destroy() : false;
                        status = false;
                    },

                    start: function(el) {
                        let loop = false;

                        carousel.querySelectorAll('.swiper-slide').length <= 1 ? loop = false : loop = true

                        swiper = new Swiper(carousel, {
                            centeredSlides: true,
                    	    modules: [Lazy, Navigation, Pagination],
                    	    lazy: {
                                checkInView: true,
                                loadPrevNext: true,
                                loadPrevNextAmount: 2
                            },
                            loop: loop,
                            pagination: {
                                el: carousel.getElementsByClassName('swiper-pagination')[0],
                                clickable: true,
                            },
                            preloadImages: false,
                            slidesPerView: 'auto',
                            spaceBetween: 20,
                            speed: 500,
                            breakpoints: {
                                // when window width is >= 768px
                                768: {
                                    centeredSlides: false,
                                    spaceBetween: 24,
                                }
                            }
                	    });

                	    status = true;
                    },

                    toggle: function() {
                        if (window.innerWidth <= 768) {
                            if (status === false) {     // in mobile, but carousel doesn't exists
                                carousels.start(carousel);
                            }

                        } else {                        // browser bigger than 1024px
                            carousels.destroy(carousel);
                        }
                    }
                }

                carousels.toggle();
                window.addEventListener('resize', carousels.toggle);
            }
        };

        const countup_carousel = function() {
            let mediaQuery = '(max-width: 768px)',
                mediaQueryList = window.matchMedia(mediaQuery),
                swiper;

            const start = ()=> {
                swiper = new Swiper(document.querySelector('.js-it_countup'), {
                    modules: [Pagination],
                    centeredSlides: true,
                    loop: true,
                    on: {
                        slideChange: () => {
                            window.it_countUpInit();
                        }
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    slidesPerView: 'auto',
        	    });
            }

            mediaQueryList.addEventListener('change', event => {
                event.matches ? start() : swiper.destroy();
            })

            mediaQueryList.matches ? start() : false;
        }

        window.countup_carousel = countup_carousel;

        const it_countup = document.querySelector('.js-it_countup');
        if (it_countup) {
            countup_carousel();
        }

        const it_departments = document.querySelector('.js-it_departments');
        if (it_departments) {
            const swiper = new Swiper(it_departments, {
                createElements: true,
                freeMode: true,
                modules: [FreeMode, Mousewheel],
                mousewheel: {
                   invert: true,
                },
                slidesPerView: 'auto',
                speed: 500,
            });
        };

        const it_hire = document.querySelector('.js-it_hire');
        if (it_hire) {
            let mediaQuery = '(max-width: 768px)',
                mediaQueryList = window.matchMedia(mediaQuery),
                description = document.querySelector('.c-it_hire__description'),
                item_box = it_hire.querySelectorAll('.swiper-slide'),
                item_desc,
                swiper,
                tl;

            description ? item_desc = description.querySelectorAll('li') : false;

            const start = ()=> {
                swiper = new Swiper(it_hire, {
                    modules: [Pagination],
                    centeredSlides: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    slidesPerView: 'auto',
                    on: {
                        slideChangeTransitionEnd: (obj) => {
                            if (description) {
                                for (let i = 0; i < item_desc.length; i ++) {
                                    item_box[i].classList.remove('is-rollout');
                                    item_desc[i].classList.remove('is-visible', 'fade-in');
                                }
                                item_box[obj.realIndex].classList.add('is-rollout');
                                item_desc[obj.realIndex].classList.add('is-visible');
                                setTimeout(() => {
                                    item_desc[obj.realIndex].classList.add('fade-in');
                                }, 200);
                            }
                        }
                    }
                });
            }

            mediaQueryList.addEventListener('change', event => {
                event.matches ? start() : swiper.destroy();
            })

            mediaQueryList.matches ? start() : false;

            const action = (e) => {
                const _that = e.currentTarget;

                if (!_that.classList.contains('is-rollout')) {
                    for (let i = 0; i < item_desc.length; i ++) {
                        item_box[i].classList.remove('is-rollout');
                        item_desc[i].classList.remove('is-visible', 'fade-in');
                    }

                    _that.classList.add('is-rollout');
                    item_desc[thisIndex(_that)].classList.add('is-visible');
                    setTimeout(() => {
                        item_desc[thisIndex(_that)].classList.add('fade-in');
                    }, 200);

                    //document.querySelector('.c-it_hire').scrollIntoView({ behavior: "smooth", block: "start" });
                }
            }

            const start_action = () => {
                for( let i = 0; i < item_box.length; i ++ ) {
                    item_box[i].addEventListener('click', action);
                    if (item_box[i].classList.contains('is-rollout')) {
                        item_desc[i].classList.add('is-visible', 'fade-in');
                    }
                }
            }

            description ? start_action() : false;
        };
/*

        const it_platforms = document.querySelector('.js-it_platforms');
        if (it_platforms) {
            const swiper = new Swiper(it_platforms, {
                freeMode: true,
                modules: [FreeMode],
                slidesPerView: 'auto',
                speed: 500,
            });
        };
*/

        const lifeCarousel = function() {
            const carousel = life;
            let status = false, mobile = false, desktop = false, swiper;

            const methods = {
                start_desktop: function(el) {
                    swiper = new Swiper(carousel, {
                        centeredSlides: true,
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            scale: .9,
                            slideShadows: false,
                        },
                        createElements: true,
                        effect: "coverflow",
                        lazy: {
                            checkInView: true,
                            loadPrevNext: true,
                            loadPrevNextAmount: 3
                        },
                        modules: [EffectCoverflow, Lazy, Navigation, Pagination],
                        navigation: {
                            nextEl: life.parentNode.getElementsByClassName('swiper-button-next')[0],
                            prevEl: life.parentNode.getElementsByClassName('swiper-button-prev')[0]
                        },
                        loop: true,
                        preloadImages: true,
                        slidesPerView: 'auto',
                        speed: 500,
                        spaceBetween:15,
                        pagination: {
                            el: life.parentNode.getElementsByClassName('swiper-pagination')[0],
                            clickable: true
                        },
                        slideToClickedSlide: true
                    });
            	    desktop = true;
                },

                start_mobile: function(el) {
                    swiper = new Swiper(carousel, {
                        centeredSlides: true,
                        createElements: true,
                        lazy: {
                            checkInView: true,
                            loadPrevNext: true,
                            loadPrevNextAmount: 3
                        },
                        modules: [Lazy, Pagination],
                        loop: true,
                        preloadImages: false,
                        slidesPerView: 'auto',
                        speed: 500,
                        spaceBetween:15,
                        pagination: {
                            el: life.parentNode.getElementsByClassName('swiper-pagination')[0],
                            clickable: true
                        },
                    });
            	    mobile = true;
                },

                toggle: function() {
                    if (window.innerWidth <= 980) {
                        ((mobile === false) && (desktop === false)) ? methods.start_mobile(carousel) : false;
                        if (desktop === true) {
                            swiper.destroy();
                            methods.start_mobile(carousel);
                        }

                    } else {
                        ((mobile === false) && (desktop === false)) ? methods.start_desktop(carousel) : false;
                        if (mobile === true) {
                            swiper.destroy();
                            methods.start_desktop(carousel);
                        }
                    }
                }
            }

            methods.toggle();
            window.addEventListener('resize', methods.toggle);
        };

        const practicesInternshipsCarousel = function() {
            let articles, triggerRes = 920, status = false;

            const swiperBlocks = new Swiper(practicesInternships, {
                centeredSlides: true,
                modules: [Pagination],
                pagination: {
                    el: practicesInternships.parentNode.getElementsByClassName('swiper-pagination')[0],
                    clickable: true
                },
                slidesPerView: 1,
            });

            swiperBlocks.on('slideChange', () => {
                articles.slideTo(swiperBlocks.realIndex);
                articles.slideTo(swiperBlocks.realIndex);
            });

            const swiperArticles = function() {
                articles = new Swiper(document.querySelector('.js-practicesInternshipsArticleSwiper'), {
                    allowTouchMove: false,
                    autoHeight: true,
                    createElements: true,
                    slidesPerView: 1,
                });
            }

            const toggle = () => {
                if (window.innerWidth <= triggerRes) {
                    if (status === false) {
                        swiperArticles();
                        status = true;
                    }
                } else {
                    if (status === true) {
                        articles.destroy(1,1);
                        status = false;
                    }
                }
            }

            toggle();
            window.addEventListener("resize", debounce( () => toggle(), 150 ));
        }

        const numbersCarousel = function() {
            const carousel = numbers;
            let status = false, swiper;

            const methods = {
                destroy: function(el) {
                    status === true ? swiper.destroy() : false;
                    status = false;
                },

                start: function(el) {
                    swiper = new Swiper(carousel, {
                        centeredSlides: true,
                        createElements: true,
                        modules: [Pagination],
                        loop: true,
                        pagination: {
                            el: carousel.parentNode.getElementsByClassName('swiper-pagination')[0],
                            clickable: true,
                        },
                        slidesPerView: 'auto',
                        spaceBetween: 16,
                        speed: 500
            	    });

            	    status = true;
                },

                toggle: function() {
                    if (window.innerWidth <= 640) {
                        if (status === false) {
                            methods.start(carousel);
                        }

                    } else {
                        methods.destroy(carousel);
                    }
                }
            }

            methods.toggle();
            window.addEventListener('resize', methods.toggle);
        };

        areas ? areasCarousel() : false;
        benefits ? benefitsCarousel() : false;
        benefits2 ? benefits2Carousel() : false;
        departments ? departmentsCarousel() : false;
        devprogram ? devprogramCarousel() : false;
        header ? headerCarousel() : false;
        heropro ? heroProCarousel() : false;
        life ? lifeCarousel() : false;
        numbers ? numbersCarousel() : false;
        practicesInternships ? practicesInternshipsCarousel() : false;
        hybrid.length > 0 ? hybridCarousel() : false;
    }

}, false)
